import React from "react"
import Layout from "@components/kaigai-fx/layout";
import 'tw-elements';

// 関数呼び出し
import { TermComponent } from "@components/kaigai-fx/term-component.js";

const TermPageK = () => {
  // 設定項目 ================================================================================================
  const myLine = 'か行'
  const myID = 'ka_'
  const pathName = 'term-ka';
  const termItem = [ // 改行するときは<br>を追加
  {
    Title:'買い決済',
    Content:'売りポジションを保有しているとき、反対売買である「買い」をして決済し、取引を終了させること。<br>決済注文を出して利益や損失を確定させます。'
  },
  {
    Title:'外国為替市場',
    Content:'円やドルなどの異なる通貨を交換（売買）する場のこと。'
  },
  {
    Title:'買いポジション',
    Content:'買い越している状態のこと（ロングポジションともいう）<br>買いポジションを保有した時から価格が上昇したときに収益が上がり、<br>価格が下落したときは損失が発生します。'
  },
  {
    Title:'カバー取引',
    Content:'証券会社やFX会社が顧客から受けた注文と同じもしくはそれに近い取引量を発注することです。<br>カバーディールともいう。'
  },
  {
    Title:'為替変動リスク',
    Content:'外国の金融商品に投資した場合の換金時に為替レートの変動で為替差損益が生じる可能性のことです。'
  },
  {
    Title:'逆指値注文',
    Content:'あらかじめ指定した条件（株価）以上になったら買い、以下になったら売る注文方法です。'
  },
  {
    Title:'強制決済',
    Content:'口座の残高がFX業者が定めている証拠金維持率を下回ると、<br>強制的に反対売買（買いポジションなら売り、売りポジションなら買い）が行われる仕組みのことです。<br>強制ロスカット、ゼロカットともいう<br>急激な価格変度が起きても、追証が発生することがありません。'
  },
  {
    Title:'許容スリップ',
    Content:'注文価格と約定価格のズレ（スリッページ）の変動幅を制限する機能です。<br>設定した変動幅を超えて不利な方向にレートが変動した場合、売買を不成立にすることができます。'
  },
  {
    Title:'金融商品取引業者',
    Content:'金融商品取引業を行うことについて、内閣総理大臣から、金融商品取引法第29条の規定により、<br>登録を受けた業者のことです。<br>証券会社やベンチャーキャピタル、投資顧問会社などが該当します。'
  },
  {
    Title:'クイック注文＝成り行き注文',
    Content:'売買を行うときに価格を設定せずに、現在表示されている株価で発注する注文方法のことです。'
  },
  {
    Title:'口座資産',
    Content:'確定している口座の残高のことです。'
  }
  ]
  // =================================================================================================================================
  // term-component.jsにて詳細設定
  let termPageContent = TermComponent( myLine, myID, termItem, pathName ); 
  
  return (
  <>
    <Layout>
      {termPageContent}
    </Layout>
  </>
  )
}
export default TermPageK